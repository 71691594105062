const gridWidthHelper = (index: number, length: number) => {
	const articlesRemaining: number = length - index;

	while (index >= 5) {
		index -= 5;
	}

	if (articlesRemaining >= 2) {
		if (index === 0 || index === 1) {
			return 6;
		} else {
			return 4;
		}
	} else if (articlesRemaining === 1) {
		if (index === 1) {
			return 6;
		} else {
			return 4;
		}
	}
};

export default gridWidthHelper;
