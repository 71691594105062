import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useCallback, useMemo } from 'react';
import { removeWidows } from 'string-remove-widows';
import MetroSans from '../../fonts/metro-sans/metroSans';
import { useScrollContext } from '../../hooks/useScrollContext';
import colors from '../../styles/colors';
import { largeText, mobileTinyText, smallText, tinyText } from '../../styles/dimensions';
import { Content } from '../sections/blog/blog';

interface Props {
	content: Content;
	size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const SmallArticle = (props: Props) => {
	const { content, size } = props;

	const scroll = useScrollContext();

	const classes = useClasses();

	const dateTime = useMemo(() => {
		return new Date(content.date).toISOString();
	}, [content.date]);

	const handleImageLoad = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	const descriptionSubstring = useMemo(() => {
		if (content.description.length > 300) {
			return content.description.substr(0, 300) + '...';
		}
		return content.description;
	}, [content.description]);

	return (
		<Grid item xs={12} sm={6} md={size} className={classes.gridItem}>
			<Link to={content.path}>
				<article>
					<GatsbyImage
						image={content.src}
						alt={content.alt}
						className={classes.image}
						onLoad={handleImageLoad}
					/>

					<div>
						<span className={classes.category}>{content.category}</span>
						<span className={classes.date}>
							<time dateTime={dateTime}>{content.date}</time>
						</span>
					</div>

					<h4
						className={classes.header}
						dangerouslySetInnerHTML={{ __html: removeWidows(content.title).res }}></h4>
					<p className={classes.description}>{descriptionSubstring}</p>
				</article>
			</Link>
		</Grid>
	);
};

export default SmallArticle;

const useClasses = makeStyles({
	category: {
		textTransform: 'uppercase',
		fontFamily: MetroSans.light,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		letterSpacing: '0.10714285714285714em',
		'@media (max-width: 599px)': {
			fontSize: mobileTinyText.fontSize,
			lineHeight: mobileTinyText.lineHeight,
		},
	},
	date: {
		textTransform: 'uppercase',
		fontFamily: MetroSans.light,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		letterSpacing: '0.10714285714285714em',
		float: 'right',
		'@media (max-width: 599px)': {
			fontSize: mobileTinyText.fontSize,
			lineHeight: mobileTinyText.lineHeight,
			letterSpacing: '0.125em',
		},
	},
	header: {
		marginTop: '0.3333333333333333em',
		marginBottom: '0.6666666666666666em',
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
			marginBottom: '0.3333333333333333em',
		},
		'@media (min-width: 600px)': {
			minHeight: `calc(${largeText.lineHeight} * 2)`,
		},
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		marginTop: '1em',
		marginBottom: 0,
		'@media (max-width: 599px)': {
			marginTop: '0.5em',
		},
	},
	gridItem: {
		paddingTop: '2em !important',
		paddingBottom: '2em !important',
	},
	image: {
		marginBottom: '1.5em',
	},
});
