import { makeStyles } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import content from '../../../content/blog/intro.json';
import { defaultSectionWidth, regularText } from '../../../styles/dimensions';
import colors from '../../../styles/colors';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import { splitData } from '../../../helpers/splitData';

const Intro = () => {
	const classes = useClasses();

	const headerRef = useRef<HTMLHeadingElement>(null);

	useEffect(() => {
		splitData(1.3, 0.025);

		setTimeout(() => {
			headerRef.current.style.opacity = '1';
		}, 1);
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.headerContainer}>
				<h1 className={classes.header} ref={headerRef}>
					<span data-splitting>{content.header.titlePart1}</span>
					<br />
					<span data-splitting>{content.header.titlePart2}</span>
				</h1>
			</div>
			<hr className={classes.divider} />
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		paddingTop: 'calc(7.625em + 2px)',
		'@media (max-width: 599px)': {
			paddingTop: 'calc(3.75em + 2px)',
		},
	},
	headerContainer: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingLeft: '1em',
		paddingRight: '1em',
	},
	header: {
		'@media (max-width: 599px)': {
			fontFamily: RigrafExpanded.semiBold,
			fontWeight: 'normal',
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			margin: '1.4em 0',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	divider: {
		borderColor: colors.dividerGray,
		'@media (max-width: 599px)': {
			borderBottom: 0,
		},
	},
});
