import { FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useState, useEffect, useCallback, useMemo, ChangeEvent as ReactChangeEvent } from 'react';
import content from '../../../content/blog/blog.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import gridWidthHelper from '../../../helpers/gridWidthHelper';
import { useScrollContext } from '../../../hooks/useScrollContext';
import colors from '../../../styles/colors';
import { defaultSectionWidth } from '../../../styles/dimensions';
import LargeArticle from '../../articles/largeArticle';
import SmallArticle from '../../articles/smallArticle';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export enum Category {
	all = 'all',
	company = 'company',
	development = 'development',
	design = 'design',
}

export interface Content {
	title: string;
	description: string;
	author: string;
	category: Category;
	date: string;
	src: IGatsbyImageData;
	alt: string;
	path: string;
}

interface ClassProps {
	activeCategory: Category;
}

export interface Response {
	allMdx: {
		edges: Array<{
			node: {
				frontmatter: {
					title: string;
					author: string;
					category: string;
					date: string;
					src: {
						childImageSharp: {
							gatsbyImageData: IGatsbyImageData;
						};
					};
					alt: string;
					description: string;
				};
				slug: string;
			};
		}>;
	};
}

const Blog = () => {
	const [activeCategory, setActiveCategory] = useState<Category>(Category.all);

	const scroll = useScrollContext();

	const classes = useClasses({ activeCategory });

	useEffect(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll, activeCategory]);

	const handleCategoryChange = useCallback((event: ReactChangeEvent<{}>) => {
		// @ts-ignore
		setActiveCategory(event.target.value);
	}, []);

	const blogPostsQuery: Response = useStaticQuery(graphql`
		query {
			allMdx(sort: { fields: frontmatter___date, order: DESC }) {
				edges {
					node {
						frontmatter {
							title
							author
							category
							date(formatString: "MMM DD, YYYY")
							src {
								childImageSharp {
									gatsbyImageData
								}
							}
							alt
							description
						}
						slug
					}
				}
			}
		}
	`);

	const articlesData: Content[] = useMemo(() => {
		const data = blogPostsQuery.allMdx.edges.map((item) => {
			return {
				title: item.node.frontmatter.title,
				description: item.node.frontmatter.description,
				author: item.node.frontmatter.author,
				category: item.node.frontmatter.category.toLowerCase() as Category,
				date: item.node.frontmatter.date,
				src: item.node.frontmatter.src.childImageSharp.gatsbyImageData,
				alt: item.node.frontmatter.alt,
				path: '/blog/' + item.node.slug,
			};
		});
		if (activeCategory === Category.all) {
			return data;
		}
		return data.filter((item) => item.category === activeCategory);
	}, [activeCategory]);

	const slicedArticleData: Content[] = useMemo(() => {
		// deep copy of array
		const stringifiedData = JSON.stringify(articlesData);
		const parsedData = JSON.parse(stringifiedData);
		parsedData.shift();
		return parsedData;
	}, [activeCategory]);

	return (
		<section className={classes.section}>
			<div>
				<FormControl component="fieldset" className={classes.fieldset}>
					<RadioGroup
						name="category"
						defaultValue={content.group[0].toLowerCase()}
						className={classes.radioGroup}>
						{content.group.map((item, key) => (
							<FormControlLabel
								key={key}
								control={<Radio classes={{ root: classes.radioButtonInput }} />}
								value={item.toLowerCase()}
								label={item}
								classes={{ root: classes.label }}
								className={item.toLowerCase() === activeCategory ? classes.active : ''}
								onChange={handleCategoryChange}
							/>
						))}
					</RadioGroup>
				</FormControl>
			</div>
			{articlesData.length > 0 && (
				<>
					<div className={classes.containerLargeScreen}>
						<Link to={articlesData[0].path}>
							<Grid container spacing={4}>
								<LargeArticle content={articlesData[0]} showDescription={true} />
							</Grid>
						</Link>
						<Grid container spacing={4}>
							{slicedArticleData.map((item, key) => (
								<SmallArticle
									key={key}
									content={item}
									size={gridWidthHelper(key, slicedArticleData.length)}
								/>
							))}
						</Grid>
					</div>
					<div className={classes.containerSmallScreen}>
						<Grid container spacing={4}>
							{articlesData.map((item, key) => (
								<SmallArticle
									key={key}
									content={item}
									size={gridWidthHelper(key, slicedArticleData.length)}
								/>
							))}
						</Grid>
					</div>
				</>
			)}
		</section>
	);
};

export default Blog;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingBottom: '3.75em',
		paddingLeft: '1em',
		paddingRight: '1em',
	},
	fieldset: {
		display: 'block',
	},
	radioGroup: {
		flexDirection: 'row',
		paddingTop: '1.5em',
		paddingBottom: '1em',
	},
	radioButtonInput: {
		display: 'none',
	},
	label: {
		marginRight: '2em',
		transform: 'translateX(14px)',
		'& span:last-of-type': {
			fontFamily: MetroSans.book,
			letterSpacing: 0,
			color: colors.textGrey,
		},
	},
	active: (props: ClassProps) => ({
		'& span:last-of-type': {
			fontWeight: props.activeCategory ? 'bold' : 'normal',
		},
	}),
	containerLargeScreen: {
		'@media (max-width: 959px)': {
			display: 'none',
		},
	},
	containerSmallScreen: {
		'@media (min-width: 960px)': {
			display: 'none',
		},
	},
});
