import Layout from '../components/layout';
import SEO from '../components/seo';
import Intro from '../components/sections/blog/intro';
import BlogSection from '../components/sections/blog/blog';
import { descriptions, titles } from '../consts/seo';

const Blog = () => (
	<Layout>
		<SEO title={titles.blog} description={descriptions.blog} />

		<Intro />
		<BlogSection />
	</Layout>
);

export default Blog;
